<template>
  <div style="margin-bottom: 3em">
    <Sidebar />

    <div>
      <v-card
        class="mx-auto justify-center text-center"
        style="position: relative"
      >
        <router-link :to="`/store/${idStore}`" style="text-decoration: none;">
          <v-img
            tile
            style="border-radius: 0px;opacity: 0.8; margin-top: -2em"
            class="white--text text-center d-flex align-center justify-center"
            height="200px"
            :src="detailStore.image_url"
          >
            <v-card-title
              class="text-center d-flex justify-center"
              style=" z-index: 3; margin-top: 2.5em"
              >{{detailStore.store_name}}</v-card-title
            >
            <div
              style="position: absolute; background-color:black; opacity: 0.5;top: 12px; width: 100%; height: 300px; z-index: -1"
            ></div>
          </v-img>
        </router-link>
      </v-card>

      <v-card class="pb-7" flat>
        <div class="pt-5 ps-3">
          <p class="ps-3 style-title">{{ $t("message.law-transaction") }}</p>
        </div>
      </v-card>

      <div class="px-3">
        <table class="table table-bordered border-primary" style="padding: 0px">
          <tbody>
            <tr>
              <th>{{ $t("message.seller-name") }}</th>
              <td>{{detailStore.store_name}}</td>
            </tr>
            <tr>
              <th class="text-wrap">{{ $t("message.pic-name") }}</th>
              <td>John</td>
            </tr>
            <tr>
              <th>{{ $t("message.address-name") }}</th>
              <td>Lorem ipsum dolor sit amet consectetur adipisicing elit.</td>
            </tr>
            <tr>
              <th>{{ $t("message.FAQ-phone") }}</th>
              <td>Lorem ipsum, dolor sit amet.</td>
            </tr>
            <tr>
              <th>{{ $t("message.selling-price") }}</th>
              <td>10000</td>
            </tr>
            <tr>
              <th>{{ $t("message.additional-fee") }}</th>
              <td>500</td>
            </tr>
            <tr>
              <th>{{ $t("message.payment-guide") }}</th>
              <td>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
                officiis dolorem
              </td>
            </tr>
            <tr>
              <th>{{ $t("message.delivery-guide") }}</th>
              <td>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
                officiis dolorem
              </td>
            </tr>
            <tr>
              <th>{{ $t("message.cancel-order/support") }}</th>
              <td>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
                officiis dolorem
              </td>
            </tr>
            <tr>
              <th>{{ $t("message.info-out-of-stock") }}</th>
              <td>Lorem ipsum dolor sit amet consectetur adipisicing elit.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-5 d-flex justify-center">
      <router-link to="/" style="text-decoration: none">
        <v-btn
          depressed
          color="#ff0090"
          style="color: white; padding: 0px;text-transform: none;"
          width="180px"
          height="35px"
          class="p-0"
        >
          {{ $t("message.back-to-top") }}
        </v-btn>
      </router-link>
    </div>

    <CategoryFooter />
  </div>
</template>

<script>
import User from "../../components/User.vue";
import LoadingPage from "../../components/LoadingPage.vue";
import StoreImage from "../../components/StoreImage.vue";
import CategoryFooter from "../../components/CategoryFooter.vue";
import ProductSlide from "../../components/ProductSlide.vue";
import Sidebar from "../../components/Sidebar.vue";
import HeaderNavigasi from "../../components/HeaderNavigasi.vue";
import { mdiMagnify, mdiHeartOutline, mdiCart, mdiStore } from "@mdi/js";
export default {
  name: "ShopInfo",
  data() {
    return {
      notFound: require("../../assets/image-not-found.png"),
      idStore: "",
      isLoading: false,
    };
  },
  components: {
    Sidebar,
    HeaderNavigasi,
    ProductSlide,
    CategoryFooter,
    StoreImage,
    LoadingPage,
    Username: User,
  },
  beforeMount(){
    window.scrollTo(0,0)
  },
  methods: {
    async dispatchStore() {
      this.idStore = this.$router.history.current.params.id;
      let id = this.$router.history.current.params.id;
      this.$store.dispatch("shop_module/fetchStore", { id: id });
    },
  },
  computed: {
    detailStore() {
      return this.$store.state.shop_module.store;
    },
  },
  created() {
    this.dispatchStore();
  },
};
</script>

<style>
.style-title {
  /* text-transform: capitalize; */
  border-left: 10px solid #ff0090;
  font-size: 16px;
  color: #424242;
}
table {
  min-width: 95%;
  margin: auto;
  border: 1px solid #707070;
}
td {
  min-width: 40%;
  flex: 1;
}
tr {
  display: flex;
  flex-wrap: wrap;
}
tr + tr {
  border-top: 2px solid #707070;
  border-color: #707070;

  padding: 0px !important;
}
tr th {
  background-color: #e0e0e0;
  width: 33%;
  text-align: left;
  padding: 5px;
  /* text-overflow: w; */
}
tr td {
  padding: 5px !important;
}
</style>
